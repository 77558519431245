// Global
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import Video from 'helpers/Video/Video';
import { tv } from 'tailwind-variants';
import Button from 'helpers/Button/Button';
import YouTubeThumbnail from 'helpers/YoutubeThumbnail/YoutubeThumbnail';

export type MediaVideoProps = ComponentProps & DartComponents.MediaVideo.MediaVideoList;
export type MediaVideo = DartComponents.MediaVideo.MediaVideo;

export type videoWidht = 'full-width' | 'half-width' | 'one-third-width' | undefined;

const tailwindVariants = tv({
  slots: {
    wrapper: ['bg-components-video-color-bg'],
    base: [
      'grid',
      'md:gap-spacing-space-between-large-6',
      'gap-spacing-space-between-large-5',
      'md:p-spacing-spacing-10',
      'p-spacing-spacing-5',
    ],
    captionContainer: [
      'flex',
      'flex-col',
      'p-spacing-spacing-5',
      'gap-spacing-spacing-4',
      'bg-components-media-caption-color-bg',
    ],
    caption: [
      'text-components-media-caption-color-text',
      'font-header-small-small',
      'leading-header-small-small',
      'text-header-small-small',
    ],
    subtitle: [
      'text-components-media-caption-color-text',
      'font-header-large-xxSmall',
      'leading-header-large-xxSmall',
      'text-header-large-xxSmall',
    ],
    description: [
      'text-components-media-caption-color-text',
      'font-bodySans-medium',
      'leading-bodySans-medium',
      'text-bodySans-medium',
    ],
    button: [
      'absolute',
      'top-[-80px]',
      'lg:right-[-70px]',
      'right-[20px]',
      'bg-components-button-color-outline-white-default-bg',
      '!z-[99999]',
      'md:w-[48px]',
      'w-[50px]',
      'max-md:!px-[5px]',
      'max-md:!py-[10px]',
    ],
    playButton: [
      'h-[40px]',
      'md:w-[48px]',
      'md:h-[48px]',
      'w-[40px]',
      '!border-0',
      '!rounded-[50%]',
      'absolute',
      'left-1/2',
      'top-1/2',
      'transform',
      '-translate-x-1/2',
      '-translate-y-1/2',
    ],
    mediaCard: ['bg-components-media-caption-color-bg'],
    mediaWrapper: ['relative', 'cursor-pointer'],
  },
  variants: {
    width: {
      'full-width': {
        base: ['grid-cols-1'],
        mediaCard: ['text-small'],
      },
      'half-width': {
        base: ['md:grid-cols-2', 'grid-cols-1'],
        mediaCard: ['flex', 'flex-col'],
      },
      'one-third-width': {
        base: ['md:grid-cols-3', 'grid-cols-1'],
        mediaCard: ['flex', 'flex-col'],
      },
    },
  },
});

/**
 * Width Options
 1. Full width
 2. Half width
 3. 1/3 width 
 */
const MediaVideo = (props: MediaVideoProps): JSX.Element => {
  const id = props?.params?.RenderingIdentifier;

  // Params to check width of the video.
  const { width } = props?.params || '';

  // Tailwind variants
  const {
    wrapper,
    base,
    mediaCard,
    mediaWrapper,
    captionContainer,
    caption,
    subtitle,
    description,
    button,
    playButton,
  } = tailwindVariants({
    width: width as videoWidht,
  });

  const [modalIsOpen, setModalIsOpen] = useState<{ [key: number]: boolean }>({});

  // Function to open modal for a specific item
  const openModal = (id: number) => {
    setModalIsOpen({ ...modalIsOpen, [id]: true });
  };

  // Function to close modal for a specific item
  const closeModal = (id: number) => {
    setModalIsOpen({ ...modalIsOpen, [id]: false });
  };

  // Dictionary Item for aria-label
  const i18n = useI18n();
  const Play = i18n.t('Play');
  const Close = i18n.t('Close');

  Modal.setAppElement(id);

  return (
    <div className={wrapper()} data-component="authorable/MedioVideo" id={id ? id : undefined}>
      <Container className={base()}>
        {props?.fields?.videos?.map((media: MediaVideo, index) => {
          return (
            <>
              <div className={mediaCard()}>
                {/* Modal for each item */}
                <Modal
                  isOpen={modalIsOpen[index] || false}
                  onRequestClose={() => closeModal(index)}
                  contentLabel={media?.fields?.caption?.value}
                  className="modal"
                  overlayClassName="overlay"
                  shouldCloseOnOverlayClick={false}
                >
                  <Video
                    field={media?.fields?.videoId}
                    responsive
                    class="aspect-video [&_iframe]:rounded-none"
                    autoplay={true}
                    controls={true}
                  />
                  <Button
                    title={Close}
                    iconLeft="close"
                    childClass={button()}
                    tag="button"
                    type="outline"
                    color="brand"
                    onClick={() => closeModal(index)}
                  />
                </Modal>
                {media?.fields?.videoId?.value && (
                  <div className={mediaWrapper()} onClick={() => openModal(index)}>
                    <>
                      <Button
                        title={Play}
                        type="fill"
                        iconLeft="play_arrow-fill"
                        tag="button"
                        childClass={playButton()}
                        onClick={() => openModal(index)}
                      />
                      <YouTubeThumbnail videoId={media?.fields?.videoId?.value} />
                    </>
                  </div>
                )}
                <div className={captionContainer()}>
                  {media?.fields?.caption?.value && (
                    <div className={caption()}>
                      <Text field={media?.fields?.caption} />
                    </div>
                  )}
                  {media?.fields?.subtitle?.value && (
                    <div className={subtitle()}>
                      <Text field={media?.fields?.subtitle} />
                    </div>
                  )}
                  {media?.fields?.description?.value && (
                    <div className={description()}>
                      <RichTextA11yWrapper field={media?.fields?.description} />{' '}
                    </div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </Container>
    </div>
  );
};

export default MediaVideo;
