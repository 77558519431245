// Global
import { Field, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import {
  FilterEqual,
  SearchResultsInitialState,
  SearchResultsStoreState,
  WidgetDataType,
  useSearchResults,
  widget,
} from '@sitecore-search/react';
import { CardViewSwitcher, Pagination, Presence } from '@sitecore-search/ui';
import Parser from 'html-react-parser';
import { useI18n } from 'next-localization';
import { useEffect, useState, useRef, useCallback } from 'react';

// Lib
import { DartPages } from 'lib/templates/Project.Dart.model';
import { getStaticAssetUrl } from 'lib/utils/public-url-utils';
import { useTheme } from 'lib/context/ThemeContext';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import PowerReviewsPrductListWithSearch from 'helpers/PowerReviews/PowerReviwsProductListWithSearch';

import tailwindVariantProduct, {
  tailwindMain,
  tailwindProductList,
  tailwindVariantProductSearch,
} from './tailwindVariant';
import { focusTrap } from 'src/stories/helpers/CustomeHelpers/FocusTrap';
import Adimo from 'helpers/Adimo/Adimo';
import Label from 'helpers/Label/Label';

export type ProductModel = {
  id: string;
  name: string;
  productbadge: string;
  productcategory: string;
  productsubcategory: string;
  producttitle: string;
  description: string;
  productlabel: string;
  productrating: string;
  image_url: string;
  url: string;
  source_id?: string;
  product_information_item_id: string;
  product_page_item_id: string;
  target?: string;
  tool_tip_title?: string;
  label_url?: string;
  adimo_add_to_cart?: string;
  adimo_buy_online?: string;
  adimo_where_to_buy?: string;
};

type SearchResultsProps = {
  defaultSortType?: SearchResultsStoreState['sortType'];
  defaultPage?: SearchResultsStoreState['page'];
  defaultItemsPerPage?: SearchResultsStoreState['itemsPerPage'];
  defaultKeyphrase?: SearchResultsStoreState['keyphrase'];
  resultsPerPage?: Field<number>;
  noSearchResult?: Field<string>;
  isBadgeFullWidth?: string | undefined;
  primaryCTAColor?: Field<string>;
  primaryCTAType?: Field<string>;
};

type InitialState = SearchResultsInitialState<
  'itemsPerPage' | 'keyphrase' | 'page' | 'sortType' | 'selectedFacets'
>;

export type CategoryListPage = DartPages.CategoryListPage;
type SearchSettings = {
  previewSource: string;
  source: string;
};
const defaultCardView = CardViewSwitcher.CARD_VIEW_GRID;

export const FragranceListWithSearchComponent = ({
  defaultSortType = 'featured_desc',
  defaultPage = 1,
  defaultKeyphrase = '',
  defaultItemsPerPage = 20,
  noSearchResult,
  resultsPerPage,
  isBadgeFullWidth,
  primaryCTAColor,
  primaryCTAType,
}: SearchResultsProps) => {
  const { sitecoreContext } = useSitecoreContext();

  const categoryPage = sitecoreContext?.route as CategoryListPage;
  const isMikMakEnabled = sitecoreContext?.integrationMikMak?.isMikMak === '1';
  const adimoMultiTouchPoint = sitecoreContext?.integrationEvidon?.isAdimoMultiTouchpoint === '1';
  const isAdimoPriceFirst = sitecoreContext?.integrationEvidon?.isAdimoPriceFirst === '1';

  const searchSettings = sitecoreContext?.searchDetails as SearchSettings;
  //let searchSourceIds = searchSettings?.source?.split('|') || [];
  const searchSourceIds = process.env.IS_PREVIEW_SITE
    ? searchSettings?.previewSource?.split('|') || []
    : searchSettings?.source?.split('|') || [];

  const preSelectFacetValue =
    categoryPage?.fields?.isCategoryBasedOnSearchResult?.value &&
    categoryPage?.fields?.categoryNameForSearchIndex?.value
      ? {
          facetId:
            categoryPage?.fields?.categoryType?.value == 'Subcategory' ? 'parallelCategory' : '',
          facetValueText: categoryPage?.fields?.categoryNameForSearchIndex?.value,
        }
      : null;
  const {
    widgetRef,
    actions: { onPageNumberChange },
    state: { page, itemsPerPage },
    queryResult: { isFetching, data: { total_item: totalItems = 0, content: products = [] } = {} },
  } = useSearchResults<ProductModel, InitialState>({
    query: (query) => {
      query
        .getRequest()
        .setSources(searchSourceIds)
        .setSearchFilter(new FilterEqual('pagetype', 'Product'))
        .setSearchFacetAll(false)
        .getSearchFacet();
    },
    config: {
      facets: {
        parallelCategory: {
          type: 'text',
        },
      },
    },
    state: {
      sortType: defaultSortType,
      page: defaultPage,
      itemsPerPage: (resultsPerPage?.value as number) || defaultItemsPerPage,
      keyphrase: defaultKeyphrase,
      selectedFacets: preSelectFacetValue != null ? [preSelectFacetValue] : [],
    },
  });

  const [dir, setDir] = useState(defaultCardView);
  const onToggle = (value = defaultCardView) => setDir(value);
  console.log('Remove below code');
  console.log(onToggle);
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  type filterVisible = 0 | 1;

  const [windowSize, setWindowSize] = useState<number>();

  const [isFilterVisible, setIsFilterVisible] = useState(false);

  const [isPreviousButtonDisabled, setPreviousButtonDisabled] = useState<boolean>(false);
  const [isNextButtonDisabled, setNextButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    const initPreviousButton = (): void => {
      if (page === 1) {
        return setPreviousButtonDisabled(true);
      } else if (page !== 1 && totalPages > 1) {
        return setPreviousButtonDisabled(false);
      } else {
        return setPreviousButtonDisabled(true);
      }
    };

    const initNextButton = (): void => {
      if ((page === 1 && totalPages === 1) || (page !== 1 && page === totalPages)) {
        return setNextButtonDisabled(true);
      } else if ((page === 1 && totalPages > 1) || (page > 1 && page !== totalPages)) {
        return setNextButtonDisabled(false);
      } else {
        return setNextButtonDisabled(true);
      }
    };
    initPreviousButton();
    initNextButton();
  }, [page, totalPages]);

  const refSearchResults = useRef<HTMLDivElement>(null);
  const firstCardFocus = useRef<HTMLDivElement>(null);

  const header = typeof window !== 'undefined' ? window.document.getElementById('header') : null;

  const scrollToSearchResults = (page: number) => {
    onPageNumberChange({ page });
    const { current } = refSearchResults;
    if (firstCardFocus.current) {
      firstCardFocus.current.tabIndex = 0; // Set tabIndex to make it focusable
      firstCardFocus.current.focus();
    }
    if (current !== null) {
      setTimeout(() => {
        const currentScrollPosition = window.scrollY || window.pageYOffset;
        const elementTop = current.getBoundingClientRect().top;
        if (header) {
          const newPosition = currentScrollPosition + elementTop - header?.offsetHeight;
          window.scrollTo({ top: newPosition, behavior: 'smooth' });
        }
      }, 300);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setWindowSize(window.innerWidth);
    }

    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowSize]);

  const i18n = useI18n();
  const i18n_buyNow = i18n.t('BuyNow');
  let i18n_displayingCountForSearch = i18n.t('DisplayingCountForSearch');
  let i18n_displayingMobileCountForSearch = i18n.t('DisplayingMobileCountForSearch');
  const i18n_next = i18n.t('Next');
  const i18n_previous = i18n.t('Previous');

  const {
    productListContainer,
    cardWrapper,
    badgeBG,
    badgeContainer,
    cardMediaWrapper,
    cardMedia,
    cardMediaImage,
    cardContentWrapper,
    cardContainer,
    productInfoContainer,
    labelBg,
    productTitle,
    productDescription,
    productCardListing,
    productCard,
    ctaWrapper,
  } = tailwindVariantProduct();

  const {
    paginationContainer,
    paginationIcon,
    paginationRoot,
    paginationPage,
    paginationPageWrapper,
    paginationDisplayPage,
    paginationGroup,
  } = tailwindVariantProductSearch();

  const {
    productCardList,
    cardWrapperList,
    cardMediaWrapperList,
    cardContentWrapperList,
    cardContainerList,
    badgeContainerList,
    productCardListButton,
  } = tailwindProductList();

  const filterVisible = isFilterVisible ? 1 : 0;

  const { mainContainer, loaderWrapper, searchLoaderAnimation } = tailwindMain({
    isFilterVisible: filterVisible as filterVisible,
  });
  const refSearchLeftPart = useRef<HTMLDivElement>(null);
  const toggleFilterMenu = useCallback(() => {
    setIsFilterVisible(!isFilterVisible);
  }, [isFilterVisible]);

  useEffect(() => {
    if (isFilterVisible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [isFilterVisible]);

  useEffect(() => {
    const previouslyFocussed = document?.activeElement;
    isFilterVisible &&
      refSearchLeftPart?.current &&
      focusTrap(refSearchLeftPart.current, toggleFilterMenu);
    return () => {
      previouslyFocussed && (previouslyFocussed as HTMLElement).focus();
    };
  }, [isFilterVisible, toggleFilterMenu]);

  const deviceName = windowSize && windowSize >= 996 ? 'Desktop' : 'Mobile';

  const { themeName } = useTheme();
  const badgeSVG = `ProductCard/Breakpoint=Medium,Brand=${themeName}`;
  const svgBG = `${getStaticAssetUrl()}/assets/${badgeSVG}.svg`;

  const paginationOf = `${itemsPerPage * (page - 1) + 1} - ${
    itemsPerPage * (page - 1) + products.length
  }`;
  const toalProductPages = totalItems as unknown as string;
  i18n_displayingCountForSearch = i18n_displayingCountForSearch
    .replace('{0}', paginationOf)
    .replace('{1}', toalProductPages);

  i18n_displayingMobileCountForSearch = i18n_displayingMobileCountForSearch
    .replace('{0}', `${page}`)
    .replace('{1}', `${totalPages}`);

  return (
    <Container>
      <div ref={widgetRef}>
        <div className={mainContainer()} ref={refSearchResults}>
          {isFetching && (
            <div className={loaderWrapper()}>
              <Presence present={true}>
                <div className={searchLoaderAnimation()} />
              </Presence>
            </div>
          )}
          {totalItems > 0 && (
            <>
              <section>
                <div className={productListContainer()}>
                  {dir === CardViewSwitcher.CARD_VIEW_GRID ? (
                    <div className={productCardListing()} ref={firstCardFocus}>
                      {products?.map((product, index) => (
                        <>
                          <div
                            className={productCard()}
                            key={index}
                            data-component="widgets/fragrancelistwithsearch/productcard"
                          >
                            <div className={cardWrapper()} key={product.id}>
                              <LinkWrapper
                                aria-label={product?.tool_tip_title}
                                suppressLinkText
                                field={{
                                  value: {
                                    href: product?.url,
                                    text: product?.producttitle,
                                    title: product?.tool_tip_title,
                                  },
                                }}
                                gtmEvent={{
                                  event: 'media',
                                  type: 'image',
                                  'gtm.element.dataset.gtmDatasourceName': product.name,
                                }}
                              >
                                <div className={cardMediaWrapper()}>
                                  {product.productbadge && (
                                    <div
                                      className={badgeContainer({
                                        isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                      })}
                                      aria-hidden="true"
                                    >
                                      <span
                                        className={badgeBG({
                                          isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                        })}
                                        style={{
                                          maskImage: `url(${svgBG})`,
                                          maskSize: 'cover',
                                          maskRepeat: isBadgeFullWidth && 'no-repeat',
                                          maskPosition: isBadgeFullWidth && 'center top',
                                        }}
                                      >
                                        {product.productbadge}
                                      </span>
                                    </div>
                                  )}
                                  {product?.image_url && (
                                    <div className={cardMedia()}>
                                      {' '}
                                      <img
                                        src={product?.image_url}
                                        alt={product?.name}
                                        className={cardMediaImage()}
                                      />
                                    </div>
                                  )}
                                </div>
                              </LinkWrapper>
                              <div className={cardContentWrapper()}>
                                <div className={cardContainer()}>
                                  {product?.productlabel && (
                                    <Label
                                      link={{
                                        value: {
                                          href: product?.label_url
                                            ? product.label_url.split('|').at(-1) || ''
                                            : '',
                                          text: product?.productlabel
                                            ? product.productlabel.split('|').at(-1) || ''
                                            : '',
                                        },
                                      }}
                                      color="white"
                                      backgroundColor={labelBg()}
                                      buttonType="default"
                                    />
                                  )}
                                  <div className={productInfoContainer()}>
                                    {product?.producttitle && (
                                      <span className={productTitle()}>
                                        <LinkWrapper
                                          aria-label={product?.tool_tip_title}
                                          field={{
                                            value: {
                                              href: product?.url,
                                              text: product?.producttitle,
                                              title: product?.tool_tip_title,
                                            },
                                          }}
                                          gtmEvent={{
                                            event: 'link',
                                            type: 'title',
                                            'gtm.element.dataset.gtmDatasourceName': product.name,
                                          }}
                                        />
                                      </span>
                                    )}
                                    <div id={`search-rating-card-snippet-${index}`}></div>
                                    {product?.description && (
                                      <div className={productDescription()}>
                                        {Parser(product?.description)}
                                      </div>
                                    )}
                                  </div>
                                </div>
                                {product?.url && (
                                  <div className={ctaWrapper()}>
                                    {isMikMakEnabled && (
                                      <Button
                                        //As the design requires a filled CTA, we do not need to add a fallback since
                                        //the button component has a default variant that displays a filled CTA.
                                        srOnlyText={product?.tool_tip_title}
                                        label={i18n_buyNow}
                                        target={product?.target}
                                        type={primaryCTAType?.value as string}
                                        color={primaryCTAColor?.value}
                                        tag="a"
                                        href={`${product?.url}${
                                          isMikMakEnabled ? '?wtbopen=1' : ''
                                        }`}
                                        gtmEvent={{
                                          event: 'cta_click',
                                          type: 'primary',
                                          'gtm.element.dataset.gtmLinkUrl': product.url,
                                          'gtm.element.dataset.gtmLinkName': product.producttitle,
                                          'gtm.element.dataset.gtmDatasourceId': product.id,
                                          'gtm.element.dataset.gtmDatasourceName': product.name,
                                        }}
                                      />
                                    )}
                                    {!isMikMakEnabled && (
                                      <>
                                        {adimoMultiTouchPoint && (
                                          <Adimo
                                            touchpointIDs={[
                                              product?.adimo_add_to_cart,
                                              product?.adimo_buy_online,
                                              product?.adimo_where_to_buy,
                                            ]
                                              .filter(Boolean)
                                              .join(',')}
                                            isMultiTouchPoint={true}
                                            type={primaryCTAType?.value}
                                            color={primaryCTAColor?.value}
                                          />
                                        )}
                                        {isAdimoPriceFirst && (
                                          <Adimo
                                            touchpointIDs={product?.adimo_buy_online}
                                            isMultiTouchPoint={false}
                                            type={primaryCTAType?.value}
                                            color={primaryCTAColor?.value}
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  ) : (
                    deviceName !== 'Mobile' && (
                      <div className={productCardListing()} ref={firstCardFocus}>
                        {products.map((product, index) => (
                          <>
                            <div className={productCardList()} key={index}>
                              <div className={cardWrapperList()} key={product.id}>
                                <LinkWrapper
                                  suppressLinkText
                                  field={{
                                    value: {
                                      href: product?.url,
                                      text: product?.producttitle,
                                      title: product?.tool_tip_title,
                                    },
                                  }}
                                  className={cardMediaWrapperList()}
                                  gtmEvent={{
                                    event: 'media',
                                    type: 'image',
                                    'gtm.element.dataset.gtmDatasourceName': product.name,
                                  }}
                                >
                                  <div>
                                    {product.productbadge && (
                                      <div
                                        className={badgeContainerList({
                                          isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                        })}
                                        aria-hidden="true"
                                      >
                                        <span
                                          className={badgeBG({
                                            isBadgeFullWidth: isBadgeFullWidth as '1' | undefined,
                                          })}
                                          style={{
                                            maskImage: `url(${svgBG})`,
                                            maskSize: 'cover',
                                            maskRepeat: isBadgeFullWidth && 'no-repeat',
                                            maskPosition: isBadgeFullWidth && 'center top',
                                          }}
                                        >
                                          {product.productbadge}
                                        </span>
                                      </div>
                                    )}

                                    {product?.image_url && (
                                      <div className={cardMedia()}>
                                        {' '}
                                        <img
                                          src={product?.image_url}
                                          alt={product?.name}
                                          className={cardMediaImage()}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </LinkWrapper>
                                <div className={cardContentWrapperList()}>
                                  <div className={cardContainerList()}>
                                    {product?.productlabel && (
                                      <Label
                                        link={{
                                          value: {
                                            href: product?.label_url
                                              ? product.label_url.split('|').at(-1) || ''
                                              : '',
                                            text: product?.productlabel
                                              ? product.productlabel.split('|').at(-1) || ''
                                              : '',
                                          },
                                        }}
                                        color="white"
                                        backgroundColor={labelBg()}
                                        buttonType="default"
                                      />
                                    )}
                                    <div className={productInfoContainer()}>
                                      {product?.producttitle && (
                                        <span className={productTitle()}>
                                          <LinkWrapper
                                            field={{
                                              value: {
                                                href: product?.url,
                                                text: product?.producttitle,
                                                title: product?.tool_tip_title,
                                              },
                                            }}
                                            gtmEvent={{
                                              event: 'link',
                                              type: 'title',
                                              'gtm.element.dataset.gtmDatasourceName': product.name,
                                            }}
                                          />
                                        </span>
                                      )}

                                      <div id={`search-rating-list-snippet-${index}`}></div>

                                      {product?.description && (
                                        <div className={productDescription()}>
                                          {Parser(product?.description)}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  {product?.url && (
                                    <div className={productCardListButton()}>
                                      {isMikMakEnabled && (
                                        <Button
                                          //As the design requires a filled CTA, we do not need to add a fallback since
                                          //the button component has a default variant that displays a filled CTA.
                                          srOnlyText={product?.tool_tip_title}
                                          label={i18n_buyNow}
                                          target={product?.target}
                                          type={primaryCTAType?.value as string}
                                          color={primaryCTAColor?.value}
                                          tag="a"
                                          href={`${product?.url}${
                                            isMikMakEnabled ? '?wtbopen=1' : ''
                                          }`}
                                          gtmEvent={{
                                            event: 'cta_click',
                                            type: 'primary',
                                            'gtm.element.dataset.gtmLinkUrl': product.url,
                                            'gtm.element.dataset.gtmLinkName': product.producttitle,
                                            'gtm.element.dataset.gtmDatasourceId': product.id,
                                            'gtm.element.dataset.gtmDatasourceName': product.name,
                                          }}
                                        />
                                      )}
                                      {!isMikMakEnabled && (
                                        <>
                                          {adimoMultiTouchPoint && (
                                            <Adimo
                                              touchpointIDs={[
                                                product?.adimo_add_to_cart,
                                                product?.adimo_buy_online,
                                                product?.adimo_where_to_buy,
                                              ]
                                                .filter(Boolean)
                                                .join(',')}
                                              isMultiTouchPoint={true}
                                              type={primaryCTAType?.value}
                                              color={primaryCTAColor?.value}
                                            />
                                          )}
                                          {isAdimoPriceFirst && (
                                            <Adimo
                                              touchpointIDs={product?.adimo_buy_online}
                                              isMultiTouchPoint={false}
                                              type={primaryCTAType?.value}
                                              color={primaryCTAColor?.value}
                                            />
                                          )}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )
                  )}
                </div>
                <div className={paginationContainer()}>
                  <Pagination.Root
                    currentPage={page}
                    defaultCurrentPage={1}
                    totalPages={totalPages}
                    onPageChange={(pagination) => {
                      scrollToSearchResults(pagination);
                    }}
                    className={paginationRoot()}
                  >
                    <Pagination.PrevPage
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSearchResults(page - 1);
                      }}
                      className={paginationIcon()}
                      tabIndex={-1}
                    >
                      <Button
                        type="text"
                        iconLeft="chevron_left"
                        label={deviceName === 'Desktop' ? i18n_previous : ''}
                        disabled={isPreviousButtonDisabled}
                        tabIndex={isPreviousButtonDisabled ? -1 : 0}
                      />
                    </Pagination.PrevPage>
                    <div className={paginationGroup()}>
                      <Pagination.Pages className={paginationPageWrapper()}>
                        {(pagination) =>
                          Pagination.paginationLayout(pagination, {
                            boundaryCount: 1,
                            siblingCount: 1,
                          }).map(({ page, type }) =>
                            type === 'page' ? (
                              <Pagination.Page
                                key={page}
                                aria-label={`Page ${page}`}
                                page={page as number}
                                onClick={(e) => e.preventDefault()}
                                className={paginationPage({
                                  currentPage: page == pagination.currentPage,
                                })}
                              >
                                {page}
                              </Pagination.Page>
                            ) : (
                              <span key={type}>...</span>
                            )
                          )
                        }
                      </Pagination.Pages>
                      {totalItems && (
                        <div
                          className={paginationDisplayPage({
                            isMobile: (windowSize && windowSize <= 996) as boolean,
                          })}
                        >
                          {windowSize && windowSize > 996
                            ? i18n_displayingCountForSearch
                            : i18n_displayingMobileCountForSearch}
                        </div>
                      )}
                      {totalItems && (
                        <div
                          className={paginationDisplayPage({
                            isMobile: (windowSize && windowSize > 996) as boolean,
                          })}
                        >
                          {i18n_displayingCountForSearch}
                        </div>
                      )}
                    </div>
                    <Pagination.NextPage
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToSearchResults(page + 1);
                      }}
                      className={paginationIcon()}
                      tabIndex={-1}
                    >
                      {' '}
                      <Button
                        type="text"
                        label={deviceName === 'Desktop' ? i18n_next : ''}
                        iconRight="chevron_right"
                        disabled={isNextButtonDisabled}
                        tabIndex={isNextButtonDisabled ? -1 : 0}
                      />
                    </Pagination.NextPage>
                  </Pagination.Root>
                </div>
              </section>
            </>
          )}
          {totalItems <= 0 && !isFetching && (
            <div>
              <h3>{noSearchResult?.value}</h3>
            </div>
          )}
        </div>
      </div>
      <PowerReviewsPrductListWithSearch products={products} />
    </Container>
  );
};
const FragranceListWithSearchWidget = widget(
  FragranceListWithSearchComponent,
  WidgetDataType.SEARCH_RESULTS,
  'content'
);

export default FragranceListWithSearchWidget;
