// Global
import React, { useEffect, useState } from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { useI18n } from 'next-localization';

// Lib
import { ComponentProps } from 'lib/component-props';
import { DartComponents } from 'lib/templates/Feature.Dart.model';
import { Common } from 'lib/templates/Foundation.Dart.model';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import Label from 'helpers/Label/Label';
import QuizResultWidget from 'components/QuizResult';

export type QuizProps = ComponentProps & DartComponents.Quiz.Quiz;
type Questionnaire = DartComponents.Quiz.Questionnaire;
type Option = DartComponents.Quiz.Option;
export type Label = Common.Label.Label;
type SelectedAnswer = {
  answer: string;
};
export const Default = (props: QuizProps): JSX.Element => {
  const { description, title, searchResultDescription, noSearchResultDescription, resultPerPage } =
    props?.fields || {};
  const [count, setCount] = useState(0);
  const router = useRouter();
  const path = useRealPathName();

  const i18n = useI18n();
  const Back = i18n.t('Back');
  const Skip = i18n.t('Skip');
  const StartOver = i18n.t('StartOver');

  const QuizResultsData = {
    resultsPerPage: resultPerPage,
    listingPageName: 'education',
    enableSearch: false,
    filterLabels: [''],
    noSearchResult: noSearchResultDescription,
    searchResultDescriptionText: searchResultDescription,
  };
  const questions: Questionnaire[] = props?.fields?.questions ?? [];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState<SelectedAnswer[]>([]);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    setCount((c) => c + 1);
  }, [router]);

  // Handle option selection
  const handleOptionClick = (option: string) => {
    setSelectedAnswers([...selectedAnswers, { answer: option }]);

    // Move to the next question or show results
    if (currentIndex + 1 < questions?.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  // Reset the quiz
  const handleRestart = () => {
    setCurrentIndex(0);
    setSelectedAnswers([]);
    setShowResults(false);
  };

  // Handle skip button
  const handleSkip = () => {
    if (currentIndex + 1 < questions.length) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  // Handle back button
  const handleBack = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  return (
    <div className="flex flex-row justify-center">
      <div>
        {title?.value.length && (
          <div>
            <Text encode={false} field={title} tag="h2" />
          </div>
        )}
      </div>
      <br />
      {!showResults ? (
        <div className="mx-8 my-8">
          {description?.value.length && (
            <div>
              <Text field={description} />
            </div>
          )}
          <h2>{questions[currentIndex]?.fields?.question?.value}</h2>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {questions[currentIndex]?.fields?.options?.map((option: Option, index) => {
              const label: Label = option?.fields?.optionValue ?? {};
              return (
                <li key={index} style={{ marginBottom: '10px' }}>
                  <button
                    style={{
                      padding: '10px 20px',
                      fontSize: '16px',
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOptionClick(label?.fields?.title?.value ?? '')}
                  >
                    {option?.fields?.optionText?.value}
                  </button>
                </li>
              );
            })}
          </ul>
          <div style={{ marginTop: '20px' }}>
            <button
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={handleBack}
              disabled={currentIndex === 0}
            >
              {Back}
            </button>
            <button
              style={{
                padding: '10px 20px',
                fontSize: '16px',
                cursor: 'pointer',
                marginLeft: '10px',
              }}
              onClick={handleSkip}
            >
              {Skip}
            </button>
          </div>
        </div>
      ) : (
        <div className="mx-8 my-8">
          <p>Result:</p>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {selectedAnswers.map((answer, index) => (
              <li key={index} style={{ marginBottom: '10px' }}>
                {answer.answer}
              </li>
            ))}
          </ul>
          <button
            onClick={handleRestart}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              fontSize: '16px',
              cursor: 'pointer',
            }}
          >
            {StartOver}
          </button>
          <br />
          <br />
          {(() => {
            QuizResultsData.filterLabels = selectedAnswers?.map((c) => c?.answer);
            QuizResultsData.enableSearch = true;
            return <QuizResultWidget {...QuizResultsData} key={path + count} rfkId="rfkid_13" />;
          })()}
        </div>
      )}
    </div>
  );
};
