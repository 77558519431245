// Global
import {
  ImageField,
  Text,
  useSitecoreContext,
  Field,
  TextField,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { Options, Splide } from '@splidejs/react-splide';
import React, { useEffect, useRef, useState } from 'react';
import { tv } from 'tailwind-variants';

// Lib
import { HeroComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import ProductCarousel, {
  ProductInformationProps,
} from 'components/authorable/Carousel/ProductCarousel';
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper, { SizedImageField } from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import ShareButton from 'helpers/ShareButton/ShareButton';
import { useRealPathName } from 'lib/utils/use-real-pathname';
import { Common } from 'lib/templates/Foundation.Dart.model';

export type Hero = HeroComponents.RenderingParameters.HeroArticle;
export type HeroArticleProps = ComponentProps &
  ProductAndArticleComponent.Articles.ArticleInformation;
export type CategoryInfo = ItemEx & DartPages.CategoryListPage;
type Label = Common.Label.Label;
export type LabelInfo = ItemEx & Common.Label.Label;

type Alignment = 'Left' | undefined;

const mainSlider: Options = {
  type: 'fade',
  rewind: false,
  pagination: false,
  arrows: false,
  autoplay: false,
  resetProgress: false,
};

const thumbSlider: Options = {
  arrows: false,
  fixedWidth: 120,
  fixedHeight: 120,
  gap: 16,
  rewind: false,
  isNavigation: true,
  perPage: 3,
  pagination: false,
  breakpoints: {
    600: {
      fixedWidth: 80,
      fixedHeight: 80,
    },
    768: {
      gap: 12,
    },
  },
};

const tailwindVariants = tv({
  slots: {
    // Here we are added product-information class for the splide slider.
    base: [
      'product-information-wrapper',
      'bg-components-article-hero-color-bg',
      'component',
      'overflow-hidden',
    ],
    totalWrapper: [
      'flex',
      'flex-col-reverse',
      'py-components-article-hero-spacing-small-padding-y',
      'px-components-article-hero-spacing-small-padding-x',
      'md:py-components-article-hero-spacing-large-padding-y',
      'md:px-components-article-hero-spacing-large-padding-x',
      'md:flex-row',
      'lg:items-center',
      'gap-components-article-hero-spacing-small-content-margin-top',
      'md:gap-spacing-spacing-8',
    ],
    heroArticleWrapper: [
      'component-content',
      'justify-center',
      'flex',
      'flex-col',
      'h-full',
      'flex-1',
    ],
    heroArticleTitle: [
      'article-title',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-product-information-color-headline',
      'relative',
      'mb-components-article-hero-spacing-small-title-margin-bottom',
      'md:mb-components-article-hero-spacing-large-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
    ],
    authorWrapper: [
      'flex',
      'flex-wrap',
      'gap-components-article-hero-spacing-small-author-thumbnail-margin-left',
      'md:gap-components-article-hero-spacing-large-author-thumbnail-margin-left',
      'mb-components-article-hero-spacing-small-details-margin-bottom',
      'md:mb-components-article-hero-spacing-large-details-margin-bottom',
    ],
    authorNameDateWrapper: [
      'flex',
      'flex-col',
      'justify-center',
      'gap-components-article-hero-spacing-small-author-name-margin-bottom',
      'md:gap-components-article-hero-spacing-large-author-name-margin-bottom',
    ],
    heroAuthorName: [
      'article-author-name',
      'font-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'leading-bodySans-large-semibold',
      'md:font-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
      'md:leading-bodySans-medium-semibold',
      'text-components-article-hero-color-detail-text',
    ],
    heroAuthorDate: [
      'article-publish-date',
      'text-components-article-hero-color-detail-text',
      'text-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
    ],
    heroAuthorImage: ['rounded-radius-radius-8', 'object-cover', 'w-[64px]', 'h-[64px]'],
    heroArticleDescriptionWrapper: [
      'field-heroArticleDescription',
      'mb-components-article-hero-spacing-small-body-margin-bottom',
      'md:mb-components-article-hero-spacing-large-body-margin-bottom',
    ],
    heroArticleDescription: [
      'article-description',
      'relative',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'leading-bodySans-medium',
      'md:font-bodySans-large',
      'md:text-bodySans-large',
      'md:leading-bodySans-large',
      'text-components-product-information-color-body',
    ],
    stateWrapper: [
      'flex',
      'flex-row',
      'mb-components-article-hero-spacing-small-stats-margin-bottom',
      'md:mb-components-article-hero-spacing-large-stats-margin-bottom',
    ],
    stateItem: ['flex'],
    stateInnerClass: ['flex', 'flex-col', 'gap-spacing-spacing-2'],
    stateCallOut: [
      'font-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'text-components-article-hero-color-detail-text',
      'leading-bodySans-small-semibold',
    ],
    stateLabel: [
      'font-header-large-xSmall',
      'text-header-large-xSmall',
      'text-components-stats-stat-text',
      'leading-header-large-xSmall',
    ],
    statDivider: [
      'min-h-[1em]',
      'w-0.5',
      'self-stretch',
      'bg-components-article-hero-color-divider',
      'mx-components-article-hero-spacing-small-space-between',
      'md:mx-components-article-hero-spacing-large-space-between',
    ],
    heroArticleCTAWrapper: ['field-heroArticletext', 'relative', '[&_a]:w-full', 'md:[&_a]:w-auto'],
    labelWrapper: [
      'flex',
      'mb-components-article-hero-spacing-small-label-share-margin-bottom',
      'md:mb-components-article-hero-spacing-large-label-share-margin-bottom',
    ],
    productInformationLeftWrapper: ['flex-1'],
    shareWrapper: ['ml-auto', 'order-2'],
  },
  variants: {
    alignment: {
      Center: {
        heroArticleWrapper: ['items-center'],
        heroArticleTitle: ['text-center'],
        heroArticleDescription: ['text-center'],
      },
      Left: {},
    },
    showPattern: {
      true: {
        heroArticleWrapper: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
      },
      false: {
        heroArticleWrapper: ['bg-components-hero-internal-color-default-bg'],
      },
    },
  },
});
const HeroArticle = (props: HeroArticleProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();

  // for storybook
  // const articleDetailPage = sitecoreContext?.route as DartPages.ArticleDetailPage;
  // const articleInfo = articleDetailPage?.fields?.productInformation;
  // const context = articleInfo?.fields;

  const articleDetailPage = sitecoreContext?.route as DartPages.ArticleDetailPage;
  const articleInfo = articleDetailPage?.fields?.articleInformation;
  const context = articleInfo?.fields;
  const categoryInfo = articleDetailPage?.fields?.category as CategoryInfo;
  const subCategoryInfo = articleDetailPage?.fields?.subCategory as CategoryInfo;
  const filterLabels = articleDetailPage?.fields?.filterLabels as Label[];
  const filterLabelPipeString = filterLabels?.map((c) => c.fields?.title?.value)?.join('|');

  const {
    description,
    ctaLink,
    headline,
    stat,
    authorImage,
    authorName,
    publishDate,
    isFeatured,
    primaryImage,
  } = context || {};
  const { alignContent } = props?.params || {};
  const alignment = alignContent !== 'Left';

  // State
  const [initialLoad, setInitialLoad] = useState(0);

  // Path
  const path = useRealPathName();

  useEffect(() => {
    const randomValue = Math.floor(Math.random() * 100) + 1;
    setInitialLoad(randomValue);
  }, [path]);

  const formatDate = (date: Field<string> | undefined) => {
    const dateString = date?.value || '';
    const [year, month, day] = dateString.split('T')[0].split('-');

    // Format the date as MM/DD/YYYY
    const formattedDate = `${month}/${day}/${year}`;
    return { value: formattedDate };
  };

  const publishedTimeStamp = (date: Field<string> | undefined) => {
    const pubDate = new Date(date?.value || '');
    return { value: pubDate.getTime() };
  };

  const {
    base,
    totalWrapper,
    heroArticleWrapper,
    heroArticleTitle,
    heroArticleDescription,
    authorWrapper,
    authorNameDateWrapper,
    heroAuthorName,
    heroAuthorDate,
    heroAuthorImage,
    stateWrapper,
    stateItem,
    stateInnerClass,
    stateCallOut,
    stateLabel,
    statDivider,
    productInformationLeftWrapper,
    heroArticleDescriptionWrapper,
    heroArticleCTAWrapper,
    labelWrapper,
    shareWrapper,
  } = tailwindVariants({
    alignment: alignment as unknown as Alignment,
  });

  const mainSliderRef = useRef<Splide>(null);
  const thumbnailSliderRef = useRef<Splide>(null);

  const prodInfo: HeroArticleProps = {
    rendering: { componentName: 'HeroArticle' },
    params: {},
    fields: {
      primaryImage: context?.primaryImage,
      secondaryImages: context?.secondaryImages,
      ctaLink: context?.ctaLink,
      description: context?.description,
      headline: context?.headline,
      label: context?.label,
    },
  };

  if (!context) return <>Article Hero</>;
  const pipeSeparatedLabelTitle = Array.isArray(context.label)
    ? context.label?.map((item: LabelInfo) => item?.fields?.link?.value?.text).join('|')
    : '';

  const pipeSeparatedLabelhref = Array.isArray(context.label)
    ? context.label?.map((item: LabelInfo) => item?.fields?.link?.value?.href).join('|')
    : '';
  return (
    <div className={base()} data-component="authorable/heroes/articlehero">
      <Container>
        <div className={totalWrapper()}>
          <div className={heroArticleWrapper()}>
            <div className={labelWrapper()}>
              <>
                {Array.isArray(context.label) &&
                  context.label?.map((item: LabelInfo, index: number) => {
                    return (
                      <Label
                        className="article-label"
                        backgroundColor="bg-components-article-hero-color-bg"
                        color={'white'}
                        link={item?.fields?.link}
                        key={index}
                      />
                    );
                  })}
                <span className="product-label hidden">
                  <a href={pipeSeparatedLabelhref}>{pipeSeparatedLabelTitle}</a>
                </span>
              </>
              {context?.socialShare?.value && (
                <div className={shareWrapper()}>
                  <ShareButton />
                </div>
              )}
            </div>
            {headline?.value && (
              <div>
                <Text className={heroArticleTitle()} encode={false} field={headline} tag="h1" />
              </div>
            )}
            {authorImage?.value?.src || authorName?.value ? (
              <>
                <div className={authorWrapper()}>
                  {authorImage?.value?.src && (
                    <ImageWrapper
                      className={heroAuthorImage()}
                      field={authorImage as ImageField | SizedImageField}
                    />
                  )}
                  {authorName?.value && (
                    <div className={authorNameDateWrapper()}>
                      <span className={heroAuthorName()}>
                        <Text encode={false} field={authorName} />
                      </span>
                      {context?.displayPublishDate && context?.displayPublishDate?.value && (
                        <span className={heroAuthorDate()}>
                          <Text encode={false} field={formatDate(publishDate) as TextField} />
                          <input
                            type="hidden"
                            name="hdnPublishedTimeStamp"
                            id="hdnPublishedTimeStamp"
                            value={publishedTimeStamp(publishDate)?.value}
                          />
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {context?.displayPublishDate && context?.displayPublishDate?.value && (
                  <div className={authorWrapper()}>
                    <div className={authorNameDateWrapper()}>
                      <span className={heroAuthorDate()}>
                        <Text encode={false} field={formatDate(publishDate) as TextField} />
                        <input
                          type="hidden"
                          name="hdnPublishedTimeStamp"
                          id="hdnPublishedTimeStamp"
                          value={publishedTimeStamp(publishDate)?.value}
                        />
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}
            {context?.description && (
              <div className={heroArticleDescriptionWrapper()}>
                <RichTextA11yWrapper className={heroArticleDescription()} field={description} />
              </div>
            )}
            {!!stat?.length && (
              <div className={stateWrapper()}>
                {context?.stat?.map((stat, index: number) => {
                  const isLast = (context?.stat?.length ?? 0) - 1 === index;

                  return (
                    <div key={index} className={stateItem()}>
                      <div className={stateInnerClass()}>
                        <div className={stateCallOut()}>
                          <Text encode={false} field={stat?.fields?.statLabel} tag="p" />
                        </div>
                        <div className={stateLabel()}>
                          <Text encode={false} field={stat?.fields?.statData} tag="span" />
                        </div>
                      </div>
                      {!isLast && <div className={statDivider()}></div>}
                    </div>
                  );
                })}
              </div>
            )}

            {ctaLink?.value?.href && (
              <div className={heroArticleCTAWrapper()}>
                <Button
                  href={ctaLink?.value?.href}
                  label={ctaLink?.value?.text}
                  tag="a"
                  target={ctaLink?.value?.target}
                  type="filled"
                >
                  <LinkWrapper field={ctaLink} />
                </Button>
              </div>
            )}
          </div>
          {primaryImage?.value?.src && (
            <div className={productInformationLeftWrapper()}>
              <ProductCarousel
                mainSliderRef={mainSliderRef}
                thumbnailSliderRef={thumbnailSliderRef}
                props={prodInfo as ProductInformationProps}
                mainSlider={mainSlider}
                thumbSlider={thumbSlider}
                key={path + initialLoad}
              />
            </div>
          )}
        </div>
        <input
          type="hidden"
          name="hdnArticleCategory"
          id="hdnArticleCategory"
          value={categoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnArticleSubCategory"
          id="hdnArticleSubCategory"
          value={subCategoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnIsFeatured"
          id="hdnIsFeatured"
          value={isFeatured?.value?.toString()}
        />
        <input
          type="hidden"
          name="hdnListingPage"
          id="hdnListingPage"
          value={articleDetailPage?.fields?.mainListingPage?.name}
        />
        <input
          type="hidden"
          name="hdnDisplayPublishDate"
          id="hdnDisplayPublishDate"
          value={props?.fields?.displayPublishDate?.value?.toString()}
        />
        <input
          type="hidden"
          name="hdnfilterLabel"
          id="hdnfilterLabel"
          value={filterLabelPipeString}
        />
      </Container>
    </div>
  );
};
export default HeroArticle;
