// Global
import { Text, useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { tv } from 'tailwind-variants';

// Lib
import { HeroComponents, ProductAndArticleComponent } from 'lib/templates/Feature.Dart.model';
import { ComponentProps } from 'lib/component-props';
import { DartPages } from 'lib/templates/Project.Dart.model';
import { ItemEx } from 'lib/templates/_.Sitecore.Override';

// Local
import Container from 'components/authorable/Layout/DartContainer/DartContainer';
import Button from 'helpers/Button/Button';
import ImageWrapper from 'helpers/ImageWrapper/ImageWrapper';
import Label from 'helpers/Label/Label';
import LinkWrapper from 'helpers/LinkWrapper/LinkWrapper';
import RichTextA11yWrapper from 'helpers/RichTextA11yWrapper/RichTextA11yWrapper';
import { Common } from 'lib/templates/Foundation.Dart.model';
import fallback from 'lib/fallback/fallback';

export type Hero = HeroComponents.RenderingParameters.HeroSds;
export type HeroSdsProps = ComponentProps & ProductAndArticleComponent.Sds.SdsInformation;
export type CategoryInfo = ItemEx & DartPages.CategoryListPage;
export type ButtonType = ProductAndArticleComponent.Sds.Button;
type Label = Common.Label.Label;

type Alignment = 'Left' | undefined;

const tailwindVariants = tv({
  slots: {
    // Here we are added product-information class for the splide slider.
    base: [
      'product-information-wrapper',
      'bg-components-article-hero-color-bg',
      'component',
      'overflow-hidden',
    ],
    totalWrapper: [
      'flex',
      'flex-col-reverse',
      'py-components-article-hero-spacing-small-padding-y',
      'px-components-article-hero-spacing-small-padding-x',
      'md:py-components-article-hero-spacing-large-padding-y',
      'md:px-components-article-hero-spacing-large-padding-x',
      'md:flex-row',
      'lg:items-center',
      'gap-components-article-hero-spacing-small-content-margin-top',
      'md:gap-spacing-spacing-8',
    ],
    heroArticleWrapper: [
      'component-content',
      'justify-center',
      'flex',
      'flex-col',
      'h-full',
      'flex-1',
    ],
    heroArticleTitle: [
      'article-title',
      'font-header-small-large',
      'leading-header-small-large',
      'text-header-small-large',
      'text-components-product-information-color-headline',
      'relative',
      'mb-components-article-hero-spacing-small-title-margin-bottom',
      'md:mb-components-article-hero-spacing-large-title-margin-bottom',
      'md:font-header-large-large',
      'md:leading-header-large-large',
      'md:text-header-large-large',
    ],
    authorWrapper: [
      'flex',
      'flex-wrap',
      'gap-components-article-hero-spacing-small-author-thumbnail-margin-left',
      'md:gap-components-article-hero-spacing-large-author-thumbnail-margin-left',
      'mb-components-article-hero-spacing-small-details-margin-bottom',
      'md:mb-components-article-hero-spacing-large-details-margin-bottom',
    ],
    authorNameDateWrapper: [
      'flex',
      'flex-col',
      'justify-center',
      'gap-components-article-hero-spacing-small-author-name-margin-bottom',
      'md:gap-components-article-hero-spacing-large-author-name-margin-bottom',
    ],
    heroAuthorName: [
      'article-author-name',
      'font-bodySans-large-semibold',
      'text-bodySans-large-semibold',
      'leading-bodySans-large-semibold',
      'md:font-bodySans-medium-semibold',
      'md:text-bodySans-medium-semibold',
      'md:leading-bodySans-medium-semibold',
      'text-components-article-hero-color-detail-text',
    ],
    heroAuthorDate: [
      'article-publish-date',
      'text-components-article-hero-color-detail-text',
      'text-bodySans-medium',
      'font-bodySans-medium',
      'leading-bodySans-medium',
    ],
    heroAuthorImage: ['rounded-radius-radius-8', 'object-cover', 'w-[64px]', 'h-[64px]'],
    heroArticleDescriptionWrapper: [
      'field-heroArticleDescription',
      'mb-components-article-hero-spacing-small-body-margin-bottom',
      'md:mb-components-article-hero-spacing-large-body-margin-bottom',
    ],
    heroArticleDescription: [
      'article-description',
      'relative',
      'font-bodySans-medium',
      'text-bodySans-medium',
      'leading-bodySans-medium',
      'md:font-bodySans-large',
      'md:text-bodySans-large',
      'md:leading-bodySans-large',
      'text-components-product-information-color-body',
    ],
    stateWrapper: [
      'flex',
      'flex-row',
      'mb-components-article-hero-spacing-small-stats-margin-bottom',
      'md:mb-components-article-hero-spacing-large-stats-margin-bottom',
    ],
    stateItem: ['flex'],
    stateInnerClass: ['flex', 'flex-col', 'gap-spacing-spacing-2'],
    stateCallOut: [
      'font-bodySans-small-semibold',
      'text-bodySans-small-semibold',
      'text-components-article-hero-color-detail-text',
      'leading-bodySans-small-semibold',
    ],
    stateLabel: [
      'font-header-large-xSmall',
      'text-header-large-xSmall',
      'text-components-stats-stat-text',
      'leading-header-large-xSmall',
    ],
    statDivider: [
      'min-h-[1em]',
      'w-0.5',
      'self-stretch',
      'bg-components-article-hero-color-divider',
      'mx-components-article-hero-spacing-small-space-between',
      'md:mx-components-article-hero-spacing-large-space-between',
    ],
    heroArticleCTAWrapper: ['field-heroArticletext', 'relative', '[&_a]:w-full', 'md:[&_a]:w-auto'],
    labelWrapper: [
      'flex',
      'mb-components-article-hero-spacing-small-label-share-margin-bottom',
      'md:mb-components-article-hero-spacing-large-label-share-margin-bottom',
    ],
    productInformationLeftWrapper: ['flex-1'],
    shareWrapper: ['ml-auto', 'order-2'],
  },
  variants: {
    alignment: {
      Center: {
        heroArticleWrapper: ['items-center'],
        heroArticleTitle: ['text-center'],
        heroArticleDescription: ['text-center'],
      },
      Left: {},
    },
    showPattern: {
      true: {
        heroArticleWrapper: [
          'bg-components-hero-internal-color-accent-bg',
          'md:bg-components-hero-internal-color-default-bg',
        ],
      },
      false: {
        heroArticleWrapper: ['bg-components-hero-internal-color-default-bg'],
      },
    },
  },
});
const HeroSDS = (props: HeroSdsProps): JSX.Element => {
  const { sitecoreContext } = useSitecoreContext();
  const fallbackComponentVariantColor = fallback?.componentVariants?.value;
  const fallbackComponentVariantType = fallback?.componentVariants?.type;

  // for storybook
  // const articleDetailPage = sitecoreContext?.route as DartPages.ArticleDetailPage;
  // const articleInfo = articleDetailPage?.fields?.productInformation;
  // const context = articleInfo?.fields;

  const sdsDetailPage = sitecoreContext?.route as DartPages.SdsDetailPage;
  const context = sdsDetailPage?.fields;
  const categoryInfo = sdsDetailPage?.fields?.category as CategoryInfo;
  const subCategoryInfo = sdsDetailPage?.fields?.subCategory as CategoryInfo;
  const filterLabels = sdsDetailPage?.fields?.filterLabels as Label[];
  const filterLabelPipeString = filterLabels?.map((c) => c.fields?.title?.value)?.join('|');

  const { description, epaNumber, formula, buttons, primaryImage, headline } = context || {};
  const { alignContent, backgroundColor } = props?.params || {};
  const alignment = alignContent !== 'Left';

  // const formatDate = (date: Field<string> | undefined) => {
  //   const dateString = date?.value || '';
  //   const [year, month, day] = dateString.split('T')[0].split('-');

  //   // Format the date as MM/DD/YYYY
  //   const formattedDate = `${month}/${day}/${year}`;
  //   return { value: formattedDate };
  // };

  const {
    base,
    totalWrapper,
    heroArticleWrapper,
    heroArticleTitle,
    heroArticleDescription,
    heroArticleDescriptionWrapper,
  } = tailwindVariants({
    alignment: alignment as unknown as Alignment,
  });

  if (!context) return <>SDS Hero</>;
  return (
    <div className={base()} data-component="authorable/heroes/sdshero">
      <Container>
        <div className={totalWrapper()}>
          <div className={heroArticleWrapper()}>
            {primaryImage?.value?.src && <ImageWrapper field={primaryImage} />}
            {headline?.value && (
              <div>
                <Text className={heroArticleTitle()} encode={false} field={headline} tag="h1" />
              </div>
            )}

            {buttons?.map((button: ButtonType) => {
              return (
                <>
                  <div>
                    <Button
                      color={
                        backgroundColor === 'BrandColor'
                          ? 'white'
                          : button?.fields?.ctaColor?.value || fallbackComponentVariantColor
                      }
                      href={button?.fields?.ctaLink?.value?.href}
                      label={button?.fields?.ctaLink?.value?.text}
                      tag="a"
                      target={button?.fields?.ctaLink?.value?.target}
                      type={button?.fields?.ctaType?.value || fallbackComponentVariantType}
                    >
                      <LinkWrapper field={button} />
                    </Button>
                  </div>
                </>
              );
            })}

            {context?.description && (
              <div className={heroArticleDescriptionWrapper()}>
                <RichTextA11yWrapper className={heroArticleDescription()} field={description} />
              </div>
            )}
          </div>
        </div>
        <input
          type="hidden"
          name="hdnArticleCategory"
          id="hdnArticleCategory"
          value={categoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnArticleSubCategory"
          id="hdnArticleSubCategory"
          value={subCategoryInfo?.fields?.categoryNameForSearchIndex?.value}
        />
        <input
          type="hidden"
          name="hdnListingPage"
          id="hdnListingPage"
          value={sdsDetailPage?.fields?.mainListingPage?.name}
        />
        <input
          type="hidden"
          name="hdnfilterLabel"
          id="hdnfilterLabel"
          value={filterLabelPipeString}
        />
        <input type="hidden" name="hdnEpaNumber" id="hdnEpaNumber" value={epaNumber?.value} />
        <input type="hidden" name="hdnFormula" id="hdnFormula" value={formula?.value} />
      </Container>
    </div>
  );
};
export default HeroSDS;
